import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash.get'
import classnames from 'classnames'
import { startsWithHttp } from 'helpers/strings'
import LinkHandler from 'components/atoms/LinkHandler'
import './TagBanner.scss'

function TagBanner({ alt, id, className, footer, src, themeClasses, title }) {
  const footerIcon = get(footer, 'icon') ? `icone-${footer.icon}` : 'icone-arrow-right'
  const footerTitle = get(footer, 'title')

  return (
    <div className={classnames('tag-banner d-flex flex-column', className)}>
      <LinkHandler className="tag-banner__link" id={id} to={get(footer, 'url')}>
        <div className="card-image-body">
          <img src={src} alt={alt} />
        </div>
        <div className={`card-image-content d-flex flex-column card card-${themeClasses || 'primary'}`}>
          {title && <div className="mb-4">{title}</div>}
          <div className={classnames({ 'align-items-center justify-content-between': !title })}>
            <span>{footerTitle}</span>
            <i
              className={`${startsWithHttp(get(footer, 'url')) ? 'icone-diagonal-arrow-right-up' : footerIcon} ml-1`}
            />
          </div>
        </div>
      </LinkHandler>
    </div>
  )
}

TagBanner.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  footer: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string,
    icon: PropTypes.string,
  }),
  src: PropTypes.string,
  themeClasses: PropTypes.string,
  title: PropTypes.string,
}

export default TagBanner
