import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Typography from 'components/atoms/Typography'
import LinkInternal from 'components/atoms/LinkInternal'
import TagBanner from 'components/molecules/TagBanner'
import get from 'lodash.get'
import { getNameColorFromHex, userCheckMobileScreen } from 'helpers/tools'

import './TagBanners.scss'

function TagBanners({ data }) {
  const themeClasses = get(data, 'theme.entity.classes')
  const id = get(data, 'id')
  const title = get(data, 'title')
  const items = get(data, 'items')
  const linkTitle = get(data, 'link.title')
  const linkUrl = get(data, 'link.url.path')
  const sectionClass = data.last ? 'section-spacing-last' : 'section-spacing'

  return (
    <Container id={id} className={`tag-banners ${themeClasses || 'bg-gray-400'} ${sectionClass}`} fluid="fluid">
      <Container>
        <Row className="">
          <Col sm={12} md={8}>
            <Typography variant="h2" cssClass="title-semibold-md title">
              {title}
            </Typography>
          </Col>

          {linkUrl && (
            <Col sm={12} md={4} className="d-flex justify-content-md-end align-items-md-center">
              <LinkInternal
                title={linkTitle}
                url={linkUrl}
                color="green-dark-100"
                icon="arrow-right"
                iconAlign="right"
              />
            </Col>
          )}
        </Row>
        <Row className="py-4 tag-banners__items">
          {Array.isArray(items) &&
            items
              .filter((i) => i.entity)
              .map((item) => (
                <Col md={4} sm={12} key={get(item, 'entity.uuid')}>
                  <TagBanner
                    alt={get(item, 'entity.image.entity.images.alt')}
                    id={get(item, 'entity.id')}
                    color={getNameColorFromHex(get(item, 'entity.color.entity.fieldColor[0].color'))}
                    src={
                      userCheckMobileScreen()
                        ? get(item, 'entity.image.entity.images.wide.url')
                        : get(item, 'entity.image.entity.images.wide.url')
                    }
                    themeClasses={get(item, 'entity.theme.entity.classes')}
                    footer={{
                      title: get(item, 'entity.link.title'),
                      icon: 'arrow-right',
                      url: get(item, 'entity.link.url.path'),
                    }}
                  />
                </Col>
              ))}
        </Row>
      </Container>
    </Container>
  )
}

export default TagBanners
